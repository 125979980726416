import * as React from "react"

function Component(props) {
  const { title } = props;

  return (
    <section id={title.toLowerCase()} className="bg-white border-b py-12">
      <div className="container max-w-5xl mx-auto m-8">

        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
          {title}
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        {props.children}

      </div>
    </section>
  );
}

export default Component;
import * as React from "react"

import { SEO } from "../components/SEO";

import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Wave1 from '../components/Wave1';
import Wave2 from '../components/Wave2';
// import Footer from '../components/Footer';

import Couple from '../components/Couple';
import Story from '../components/Story';
import Photos from '../components/Photos';
// import Wedding from '../components/Wedding';
import Donate from '../components/Donate';

import {
  // Section1,
  // Section2,
  // Section3,
  // Section4,
} from '../components/Landing';

import '../styles/global.css'

const IndexPage = () => {
  return (
    <>
      <NavBar />
      <Hero />
      <Wave1 />
      <Couple />
      <Story />
      <Photos />
      {/* <Wedding /> */}
      {/* <Section1 /> */}
      {/* <Section2 /> */}
      {/* <Section3 /> */}
      <Wave2 />
      {/* <Section4 /> */}
      <Donate />
      {/* <Footer /> */}
    </>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)
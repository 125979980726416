import * as React from "react"

import Section from './Section';

function Profile(props) {
  const { name, avatar } = props;
  return (
    <figure className="md:w-1/4 bg-gray-100 rounded-xl p-8 m-6 flex-col flex-grow">
      <img className="w-48 h-48 rounded-full mx-auto" src={avatar} alt="" width="384" height="512" />
      <div className="pt-6 md:p-8 text-center space-y-4">
        <figcaption className="font-medium">
          <div className="text-sky-500 text-2xl">
            {name}
          </div>
          <div className="text-teal-700 dark:text-teal-500 text-xl">
            {props.subtitle}
          </div>
        </figcaption>
        <blockquote>
          <p className="text-lg font-medium">
            {props.children}
          </p>
        </blockquote>
      </div>
    </figure>
  );
}

function Component() {
  return (
    <Section title="Couple">
      <div className="flex flex-wrap">

        <Profile name="賴柏翰 (Chrono)" avatar="images/profile_chrono_63.png" subtitle="the Groom">
          想像派、搞笑、超愛貓咪的雙魚男<br />
          認為 「科技始終來自於惰性」 的工程師
        </Profile>

        <Profile name="陳怡樺 (Tiffany)" avatar="images/profile_tiffany_44.png" subtitle="the Bride">
          行動派、直率、超愛貓咪的牡羊女<br />
          相信『人都有潛能及其優勢可以被看見』的社工師
        </Profile>

      </div>
    </Section>
  );
}

export default Component;
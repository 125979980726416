import * as React from "react"

import Section from './Section';
import PhotoWall from '../components/PhotoWall';


function Story(props) {
  const { title, images, invert = false } = props;
  return (
    <div className={`flex flex-wrap${invert ? ' flex-row sm:flex-row-reverse' : ''}`}>
      <div className="w-5/6 sm:w-1/2 p-6">
        <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
          {title}
        </h3>
        <p className="text-gray-600 mb-8">
          {props.children}
        </p>
      </div>
      <div className="w-full sm:w-1/2 p-6">
        <PhotoWall
          className=""
          thumbs={images}
          photos={images}
          photoAlbumProps={{
            columns: 1,
          }}
          single
        />
      </div>
    </div>
  );
}



function Component() {
  const images = {
    one: [
      // { src: 'images/undraw_Co_workers_re_1i6i.png', width: 955, height: 764 },
      { src: 'images/story/IMAG0191.jpg', width: 2592, height: 1552 },
      { src: 'images/story/2011-1.jpg', width: 2736, height: 3648 },
    ],
    two: [
      // { src: 'images/undraw_Playful_cat_re_ac9g.png', width: 1025, height: 421 },
      {src: 'images/story/IMG_7468.JPG', width: 2731, height: 1536},
      {src: 'images/story/IMG_8095.JPG', width: 2048, height: 1536},
      {src: 'images/story/IMG_0809.JPG', width: 1537, height: 2049},
      {src: 'images/story/IMG_7552.JPG', width: 1536, height: 2048},
      {src: 'images/story/IMG_7555.JPG', width: 1280, height: 2140},
    ],
    three: [
      { src: 'images/undraw_Everywhere_together_re_xe5a.png', width: 813, height: 617 },
    ],
    four: [
      // { src: 'images/undraw_Aircraft_re_m05i.png', width: 1234, height: 582 },
      {src: 'images/story/2014-1.jpg', width: 3264, height: 1840},
      {src: 'images/story/2019-6.jpg', width: 3088, height: 2320},
      {src: 'images/story/2019-4.jpg', width: 3088, height: 2320},
      {src: 'images/story/2017-1.jpg', width: 3264, height: 2448},
      {src: 'images/story/2021-1.jpg', width: 3024, height: 4032},
      {src: 'images/story/2021-7.jpg', width: 4032, height: 3024},
    ],
  };
  return (
    <Section title="Story">

      <Story title="相遇" images={images.one}>
        2009 年夏天，依照慣例 Tiffany 暑假回到師大華裔青年語文研習營（OCAC）擔任輔導員，而 Chrono 透過朋友介紹，想試試看一份跟電腦性質較無關的工作，因此也進入 OCAC 打工，2010 年 2 月 28 日農曆元宵節，Chrono 約了 Tiffany 一起去賞花燈，從人潮擁擠的會場走著走著，就一直在一起到了現在........
      </Story>

      <Story title="可可" images={images.two} invert>
        2007 - 2020，我們最愛的毛小孩，是個帥氣紳士的男生，最喜歡向 Tiffany 撒嬌，還有跟 Chrono 搶枕頭，特殊專長尋找任何可能發出聲音的方式叫 Chrono 起床，隱藏技能亂吃塑膠及挖牆壁油漆。
      </Story>

      <Story title="旅遊" images={images.four}>
        我們喜歡一起旅遊，有時會是 2 人說走就走的小旅行，偶爾也會跟朋友一起出遊，不論國內還是國外我們都喜歡，一起到不同的地方，看不同的風景，欣賞不同的文化，一同開拓視野，體驗這個世界。
      </Story>

      {/* <Story title="日本旅遊" images={images.four} invert>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
        <br />
        Images from:
        <a className="text-pink-500 underline" href="https://undraw.co/">undraw.co</a>
      </Story> */}

    </Section>
  );
}

export default Component;
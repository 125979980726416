import React from "react";
import dayjs from "dayjs";
import Countdown from 'react-countdown';

import { pad } from '../utils';

function Cell(props) {
  const { value, text } = props;
  return (
    <div className="flex-1 text-5xl text-center">
      {pad(value, 2, '0')}
      <div className="text-xs">{text}</div>
    </div>
  );
}

function Component(props) {
  const targetDate = dayjs(props.date).toDate();
  const renderer = (values) => {
    const { days, hours, minutes, seconds, completed } = values;
    const date = dayjs(values.props.date).format('YYYY.MM.DD');
    const direction = completed ? "countup" : "countdown";
    const color = completed ? "text-blue-500" : "text-red-500";
    return (
      <div className="uppercase">
        <div className="w-full text-3xl text-white font-bold">{date}</div>
        <div className={`shadow-md shadow-gray-500/50 relative p-2 ${color}`}>
          <div className="flex items-center">
            <Cell value={days} text="days" />
            <Cell value={hours} text="hours" />
            <Cell value={minutes} text="minutes" />
            <Cell value={seconds} text="seconds" />
          </div>
        </div>
        <div className="w-full text-2xl text-white font-bold">{direction}</div>
      </div>
    );
  };
  return (
    <Countdown
      date={targetDate}
      renderer={renderer}
      overtime
    />
  );
}

export default Component;
import * as React from "react"
import axios from 'axios';
import Avatar from 'react-avatar';

function Component() {
  const [comments, setComments] = React.useState([]);

  React.useEffect(() => {
    axios.get('https://script.google.com/macros/s/AKfycbw1GeDkKyCHHSRB1WOvZyRVoNaoedIyYL0adEBUYruskSdeotAnbVJkH9mjPuPmvCRx/exec')
      .then((resp) => {
        setComments(resp.data.reverse());
      })
  }, []);

  const defaultData = {
    name: '',
    comment: '',
  };
  const [data, setData] = React.useState({ ...defaultData });
  const [disabled, setDisabled] = React.useState(false);

  function onClick() {
    var formdata = new FormData();
    formdata.append('entry.228126211', data.name);
    formdata.append('entry.770188603', data.comment);

    setDisabled(true);
    axios({
      method: "post",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdxBVKRmGIQhCV8lwxibTLl3XS8GMvUglNdhiQ7gO9oSsCxcg/formResponse',
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .catch(() => {
        setDisabled(false);
        alert('留言完成！！');
        window.location.reload();
      })

  }

  return (
    <section id="donate" className="container mx-auto text-center py-6 mb-12">
      <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
        祝福留言
      </h2>
      <div className="w-full mb-4">
        <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div className="relative">

        <div className="flex mx-auto items-center justify-center shadow-lg max-w-lg">
          <div class="w-full max-w-xl bg-white rounded-lg px-4 pt-2">
            <div className="flex flex-wrap -mx-3 mb-6">
              <h2 className="px-4 pt-3 pb-0 text-gray-800 text-sm">名稱</h2>
              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <input
                  className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white placeholder:italic placeholder:text-slate-400"
                  name="body"
                  placeholder='讓我們知道您是誰'
                  required
                  value={data?.name}
                  onChange={(e) => {
                    setData({
                      ...data,
                      name: e.target.value
                    });
                  }}
                />
              </div>
              <h2 className="px-4 pt-3 pb-0 text-gray-800 text-sm">留言</h2>
              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <textarea
                  className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white placeholder:italic placeholder:text-slate-400"
                  name="body"
                  placeholder='留下對我們的祝福'
                  required
                  onChange={(e) => {
                    setData({
                      ...data,
                      comment: e.target.value
                    });
                  }}
                  value={data?.comment}
                />
              </div>
              <div className="w-full md:w-full flex items-start md:w-full px-3">
                <div className="-mr-1">
                  <input
                    type='submit'
                    className="bg-white text-gray-600 font-medium py-1 px-4 border border-gray-300 rounded-lg tracking-wide mr-1 hover:bg-gray-100 disabled:opacity-50"
                    value='留言'
                    onClick={onClick}
                    disabled={data.name.length === 0 || data.comment.length === 0 || disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-3 mx-auto flex flex-wrap mt-4 justify-center items-center">
          {comments.map((comment) => {
            return (
              <div className="bg-white rounded-md m-1 flex w-full md:w-auto">
                <div className="flex justify-center	items-center p-1">
                  <Avatar
                    name={comment.name}
                    className="rounded-full"
                    size={36}
                    round
                  />
                </div>
                <div className="p-1 text-left">
                  <div className="font-bold">
                    {comment.name}
                  </div>
                  <div>
                    {comment.comment}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

      </div>

    </section >
  );
}

export default Component;
import React from "react";
import axios from "axios";


import Countdown from './Countdown';

function Component() {
  return (
    <div id="top" className="pt-24">
      <div className="container px-3 mx-auto flex flex-wrap flex-col-reverse md:flex-row items-center">

        {/* <!--Left Col--> */}
        <div className="flex flex-col w-full md:w-3/6 justify-center items-center text-center md:text-center px-5">
          <p className="uppercase tracking-loose w-full">Happy Wedding!</p>
          <h1 className="my-4 text-5xl md:text-7xl font-bold leading-tight w-full font-cute">
            Chrono
            <div className="block xl:inline text-red-500 font-cute"> ♥️ </div>
            Tiffany
          </h1>
          <p className="leading-loose text-2xl my-4 w-full lg:w-5/6">
            <Countdown date="2022-11-22 18:30" />
          </p>
          <a className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            href="#donate">
            給予祝福
          </a>
        </div>

        {/* <!--Right Col--> */}
        <div className="w-1/2 md:w-3/6 py-1 text-center relative">
          <img className="w-4/5 mx-auto" src="images/cartoon.png" />
        </div>
      </div>

    </div >
  );
}

export default Component;
import * as React from "react"

import { Link } from "gatsby"

import Section from './Section';
import PhotoWall from '../components/PhotoWall';

import {
  photos_horizontal,
  thumbs_horizontal,
} from "../photos";

function Component() {
  return (
    <Section title="Photos">

      <section className="overflow-hidden text-gray-700 ">
        <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
          <div className="flex flex-wrap -m-1 md:-m-2">
            <PhotoWall
              thumbs={thumbs_horizontal.slice(0, 9)}
              photos={photos_horizontal.slice(0, 9)}
              photoAlbumProps={{
                columns: 3,
              }}
            />
          </div>
        </div>
      </section>

      <div className="text-center p-5">
        <Link
          id="navAction"
          className="gradient nav-item mx-auto lg:mx-0 hover:underline text-white font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          to="/gallery"
        >
          看更多！
        </Link>
      </div>

    </Section>
  );
}

export default Component;